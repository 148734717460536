import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { menu, menuAsync } from "../features";
import { menus } from "../utils/menu";
import { replaceAll } from "../utils/utils";
import { IoMenu } from "react-icons/io5";

import { Drawer } from "antd";

function Header() {
  const dispatch = useDispatch();
  const activeMenu = useSelector(menu.menu);
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  document.title = _.startCase(_.replace(activeMenu, "-", " "));

  useEffect(() => {
    const onScroll = () => setScrolled(window.pageYOffset > 50);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleClickScroll = (page) => {
    dispatch(menuAsync.menu(page));
    const element = document.getElementById(page);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    onClose();
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer title="$UpSideDownTurtle" onClose={onClose} open={open}>
        <div>
          {menus.map((m, i) => (
            <div
              key={i}
              className="px-4 pb-4 cursor-pointer hover:text-orange-400 hover:font-semibold"
              onClick={() => {
                handleClickScroll(replaceAll(m.label, " ", "-").toLowerCase());
              }}
            >
              <p
                className={`${replaceAll(m.label, " ", "-").toLowerCase() === activeMenu &&
                  "text-orange-400 font-semibold"
                  }`}
              >
                {m.label}
              </p>
            </div>
          ))}
        </div>
      </Drawer>
      <div
        className={`fixed top-0 w-full ${scrolled ? "static-main" : "backdrop-blur-sm shadow-md"
          } transform transition duration-300`}
      >
        <div className="container flex items-center justify-between gap-10 mx-auto text-white">
          <div className="flex items-center gap-2">
            <img src={logo} alt="UpSideDownTurtle" className="h-10 logo" />
            <p className="text-2xl font-bold md:text-3xl">$UpSideDownTurtle</p>
          </div>
          <div className="items-center hidden gap-3 font-medium xl:flex">
            {menus.map((m, i) => (
              <div
                key={i}
                className="px-4 pt-5 pb-6 cursor-pointer hover:text-orange-400 hover:font-semibold"
                onClick={() => {
                  handleClickScroll(
                    replaceAll(m.label, " ", "-").toLowerCase()
                  );
                }}
              >
                <p
                  className={`${replaceAll(m.label, " ", "-").toLowerCase() ===
                    activeMenu && "text-orange-400 font-semibold"
                    }`}
                >
                  {m.label}
                </p>
              </div>
            ))}
          </div>

          <IoMenu
            className="my-4 text-4xl cursor-pointer xl:hidden"
            onClick={showDrawer}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
