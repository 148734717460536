import React from "react";
import Heading from "../components/heading";

function About() {
  const about = [
    `Introducing "$UpSideDownTurtle" the world's first meme cryptocurrency with a
    shell-shocking mission: saving turtles one meme at a time!`,
    `Picture this: A group of turtles tired of slow progress in their
    shell-ter savings accounts decided to launch their own crypto to fund
    their global shell-tastic adventures! With each trade, we're one step
    closer to building a turtle utopia where slow and steady wins the
    crypto race! Join the shell-ebration, hop on the $UpSideDownTurtle train, and
    let's shell-ebrate together!`,
    ` Introducing $UpSideDownTurtle - the crypto currency with a shell of a story!`,
  ];
  return (
    <div className="bg-black">
      <div className="container py-16 mx-auto text-lg text-center text-color md:text-xl">
        <Heading title={"About $UpSideDownTurtle"} />
        <div className="max-w-4xl mx-auto">
          {about.map((a, i) => (
            <p key={i} className="mt-3 tracking-wide">
              {a}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
