import About from "../home/about";
import HowToBuy from "../home/how-to-buy";
import Landing from "../home/landing";
import RoadMap from "../home/roadmap";
import Tokenomics from "../home/tokenomics";

export const menus = [
  { label: "Home", component: <Landing /> },
  { label: "About $USDT", component: <About /> },
  { label: "How to buy", component: <HowToBuy /> },
  { label: "Tokenomics", component: <Tokenomics /> },
  { label: "Roadmap", component: <RoadMap /> },
];
