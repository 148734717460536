import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: "home",
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
});

export const { setMenu } = menuSlice.actions;

export const menuAsync = {
  menu: (menu) => (dispatch) => {
    dispatch(setMenu(menu));
  },
};

export const menu = {
  menu: (state) => state.menu.menu,
};

export default menuSlice.reducer;
