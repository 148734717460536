/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import Header from "./header";
import { menus } from "../utils/menu";
import { useDispatch } from "react-redux";
import { menuAsync } from "../features";
import _ from "lodash";
import { replaceAll } from "../utils/utils";
import Disclaimer from "../home/disclaimer";
// import Footer from "./footer";

const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

// hooks
const useScrollspy = (ids, offset = 0) => {
  const [activeId, setActiveId] = useState("");
  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "$UpSideDownTurtle");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

function Content() {
  const dispatch = useDispatch();

  const activeId = useScrollspy(
    _.map(menus, (m) => replaceAll(m.label, " ", "-").toLowerCase()),
    400
  );

  React.useEffect(() => {
    dispatch(menuAsync.menu(activeId));
  }, [activeId]);

  return (
    <div className="main-bg">
      <Header />
      {_.map(menus, (menu, i) => (
        <section key={i} id={replaceAll(menu.label, " ", "-").toLowerCase()}>
          {menu.component}
        </section>
      ))}

      <Disclaimer />
    </div>
  );
}

export default Content;
