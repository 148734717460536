import React from "react";
import disclaimer from "../assets/images/disclaimer.png";
import Heading from "../components/heading";

function Disclaimer() {
  const disc = [
    `$UpSideDownTurtle is a purely meme-based cryptocurrency created solely for
  entertainment purposes.`,
    `It lacks a formal team, a clear roadmap, and holds no financial value or utility, It's completely useless, only meant for entertainment.`,
    `Investing in $UpSideDownTurtle should not be construed as a means of
  financial gain.`,
    `Its sole purpose is to spread joy and raise awareness about saving
  turtles worldwide.`,
    `Participants are advised against expecting any form of return on
  investment.`,
    `Remember, $UpSideDownTurtle exists only to make turtles feel great!`,
  ];

  return (
    <div className="bg-black">
      <div className="container py-16 mx-auto text-color">
        <Heading title="Disclaimer" />
        <div className="flex flex-col max-w-5xl gap-8 mx-auto mt-10 md:flex-row">
          <img
            src={disclaimer}
            alt="disclaimer"
            className="object-contain min-w-lg max-h-20"
          />
          <div>
            {disc.map((d, i) => (
              <p key={i} className="mb-2 text-base tracking-wide md:text-lg">
                {i + 1} . {d}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
