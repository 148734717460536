import React from "react";
import bg from "../assets/images/tortoise.png";
import ReactSparkle from "react-sparkle";
import { FaXTwitter } from "react-icons/fa6";

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

import { useCountdown } from "../components/useCountdown";
import { end_date, presale_date } from "../utils/utils";

function Landing() {
  const social = [
    {
      icon: <FaXTwitter />,
      link: "https://x.com/upsideturtle?s=21&t=Xmu7f8im-LETZKrnMWXtUQ",
    },
  ];

  const addZero = (num) => {
    if (num < 10) return "0" + num;
    return num;
  };
  const DateTimeDisplay = ({ value, type }) => {
    return (
      <div>
        <p className="text-2xl font-extrabold">{addZero(value)}</p>
        <span className="uppercase">{type}</span>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex items-center gap-8 px-10 py-4 text-sm font-semibold text-center text-white bg-orange-400 rounded-xl">
        <DateTimeDisplay value={days} type={"Day"} />
        <p className="text-3xl">:</p>
        <DateTimeDisplay value={hours} type={"Hrs"} />
        <p className="text-3xl">:</p>
        <DateTimeDisplay value={minutes} type={"Mins"} />
        <p className="text-3xl">:</p>
        <DateTimeDisplay value={seconds} type={"Secs"} />
      </div>
    );
  };

  const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
      return (
        <div className="p-4 text-center text-white">
          <h2 className="mt-6 text-5xl font-extrabold text-green-600">
            PRESALE <span className="text-4xl text-orange-500">FINAL</span>
          </h2>
          <p className="mt-3 text-lg text-green-600 uppercase">Live now</p>
          <p className="mt-3 text-lg">
            Address to send $Sol
            <br />
            <span className="text-green-600 break-all">
              7gEiiRZK6sPmsQ5Ad4yAQiZGT8muogg5pgWcuNcTNtvV
            </span>
          </p>
          <h2 className="mt-4 font-bold text-red-600">Ends in</h2>
          <div className="flex justify-center mt-3 overflow-hidden">
            <FlipClockCountdown
              to={new Date(end_date).getTime()}
              labels={["DAYS", "HRS", "MINS", "SECS"]}
              labelStyle={{
                fontSize: 14,
                fontWeight: 500,
                // textTransform: "uppercase",
                color: "red",
              }}
              digitBlockStyle={{
                width: 25,
                height: 50,
                fontSize: 26,
                color: "red",
              }}
              duration={0.5}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="py-20 main-bg md:pb-24 md:pt-32">
      <div className="flex flex-col gap-4 md:gap-0 md:flex-row">
        <div className="container grid gap-6 mx-auto md:grid-cols-2">
          <div className="flex items-center justify-end text-white">
            <div className="max-w-xl">
              <h2 className="text-4xl leading-tight tracking-wider md:text-5xl ps-4">
                Welcome to{" "}
              </h2>
              <p className="mt-3 text-4xl font-bold text-orange-400 ps-4 xl:text-5xl">
                $USDT
              </p>
              <div className="py-3 mt-8 border-gray-500 border-s ps-8">
                <p className="text-lg font-medium sm:text-xl text-color">
                  Saving Turtles One Meme at a Time! <br />
                  Join the fun, spread the love, and help our shelled friends
                </p>
                <p className="mt-3 text-base sm:text-lg md:text-xl">
                  No financial gains, just shell-abration!{" "}
                </p>
              </div>

              <div className="mt-5 text-xl">
                <p className="mb-3 text-orange-400">Join our ambassador program</p>
                <p>Contact us on <a href="mailto:ambassador@upsidedownturtle.io" className="text-orange-400">ambassador@upsidedownturtle.io</a> to Become an ambassador</p>
              </div>
            </div>
          </div>
          <div className="relative flex items-center justify-center">
            <img
              src={bg}
              alt="UpSideDownTurtle"
              className="max-h-72 md:max-h-[25rem] rotate-[165deg]"
            />

            <div className="absolute hidden w-full h-full md:block">
              <ReactSparkle
                color="red"
                count={50}
                minSize={10}
                maxSize={15}
                overflowPx={80}
                fadeOutSpeed={10}
                flicker={false}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex gap-5 p-3 rounded-lg bg-green-800/40 md:rounded-s-lg md:flex-col">
            {social.map((s, i) => (
              <a key={i} href={s.link} rel="noreferrer" target="_blank">
                <div className="p-2 bg-white rounded-full">{s.icon}</div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-5">
        <div className="p-5 px-10 bg-black/50 rounded-xl">
          <CountdownTimer targetDate={new Date(presale_date).getTime()} />

          {/* <FlipClockCountdown
            to={new Date(presale_date).getTime()}
            labels={["DAYS", "HRS", "MINS", "SECS"]}
            labelStyle={{
              fontSize: 14,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
            digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
            duration={0.5}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Landing;
