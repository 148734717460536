import React from "react";

function Heading({ title }) {
  return (
    <h2 className="text-3xl text-center md:text-4xl font-bold mb-5 text-orange-400">
      {title}
    </h2>
  );
}

export default Heading;
