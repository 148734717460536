import React from "react";
import Heading from "../components/heading";
import { numberFormatter } from "../utils/utils";
// import { numberFormatter } from "../utils/utils";

function Tokenomics() {
  return (
    <div className="bg-black">
      <div className="container py-16 mx-auto text-color">
        <Heading title="Tokenomics" />
        <div className="flex flex-col justify-between max-w-3xl gap-5 mx-auto mt-10 md:text-lg md:flex-row">
          <div>
            <p>
              <span className="font-bold text-white">Token Name:</span>{" "}
              $UpSideDownTurtle
            </p>
            <p>
              <span className="font-bold text-white">Symbol:</span> USDT
            </p>
            <p>
              <span className="font-bold text-white">Max Supply:</span>{" "}
              {numberFormatter(1618033988749)} $USDT
            </p>
          </div>
          <div>
            <p className="">
              No taxes, no BS. Just pure turtle love. <br />
              Join the shell-ebration today
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
