import React from "react";

function HowToItem({ item, index }) {
  return (
    <>
      {index === 1 ? (
        <div className="items-center justify-center hidden row-span-2 lg:flex">
          <img
            src={item.image}
            alt="How to buy"
            className="object-contain p-6 rotate-180 max-h-80"
          />
        </div>
      ) : (
        <div
          className={`w-full other-main-bg group cursor-pointer flex flex-col md:flex-row gap-4 px-5 py-6`}
        >
          <div className="flex justify-center md:block">
            <img
              src={item.image}
              alt={item.title}
              className="object-contain w-14 min-w-14"
            />
          </div>
          <div>
            <h3 className="mb-3 text-xl font-bold">{item.title}</h3>
            <p className="transition-all duration-300 transform lg:line-clamp-4 group-hover:line-clamp-none">
              {item.description}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default HowToItem;
