import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { PiPersonArmsSpread } from "react-icons/pi";

function RoadMap() {
  const road_map = [
    {
      icon: <PiPersonArmsSpread />,
      bgColor: "rgb(24, 61, 29)",
      iconBgColor: "rgb(24, 61, 29)",
      color: "#fff",
      title: "Spread the Meme",
      description:
        "Share $UpSideDownTurtle far and wide. Let's get those turtles trending!",
    },
    {
      icon: <PiPersonArmsSpread />,
      title: "Buy and Hodl",
      bgColor: "rgb(24, 61, 29)",
      iconBgColor: "rgb(24, 61, 29)",
      color: "#fff",
      description:
        "Grab your $USDT and hold onto them tighter than a turtle to its shell!",
    },
    {
      icon: <PiPersonArmsSpread />,
      title: "Save the Tortoises",
      bgColor: "rgb(24, 61, 29)",
      iconBgColor: "rgb(24, 61, 29)",
      color: "#fff",
      description:
        "Every $UpSideDownTurtle contributes to saving our shelled friends. Together, we're making a slow but steady difference!",
    },
  ];
  return (
    <div className="container mx-auto">
      <h2 className="py-10 text-4xl font-bold text-center text-orange-400">
        Road Map
      </h2>
      <VerticalTimeline>
        {road_map.map((r, i) => (
          <VerticalTimelineElement
            key={i}
            className="vertical-timeline-element--work"
            contentStyle={{ background: r.bgColor, color: r.color }}
            contentArrowStyle={{ borderRight: `7px solid ${r.bgColor}` }}
            iconStyle={{ background: r.iconBgColor, color: "#fff" }}
            icon={<PiPersonArmsSpread />}
          >
            <h3 className="vertical-timeline-element-title">{r.title}</h3>
            <p>{r.description}</p>
          </VerticalTimelineElement>
        ))}

        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<PiPersonArmsSpread />}
        />
      </VerticalTimeline>
    </div>
  );
}

export default RoadMap;
