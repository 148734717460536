import React from "react";
import HowToItem from "../components/how-to-item";
import wallet from "../assets/images/wallet.png";
import raydium from "../assets/images/raydium.png";
import solana from "../assets/images/solana.png";
import logo from "../assets/images/logo.png";
import how from "../assets/images/turtle.png";
// import how from "../assets/images/how_to.png";
import Heading from "../components/heading";
import { useCountdown } from "../components/useCountdown";
import { presale_date } from "../utils/utils";

function HowToBuy() {
  const how_to = [
    {
      image: wallet,
      title: "Create a wallet",
      description:
        "To create a Metamask wallet or any other wallet of your choice, visit the website metamask.io for chrome extension, or download the mobile app from Android or iOS stores, set up a new wallet with a strong password and backup seed phrase, then begin buying, storing, and managing $UpSideDownTurtles securely.",
    },

    {
      image: how,
      title: "Go to Raydium",
      description:
        "Connect to Raydium by visiting https://raydium.io, connect your wallet, paste the ……. $UpSideDownTurtle contract address into Raydium, select $UpSideDownTurtle, and confirm the transaction. When prompted by Metamask for a wallet signature, sign to complete the process.",
    },
    {
      image: solana,
      title: "Get SOL",
      description:
        "Ensure your wallet contains SOL to convert to $UpSideDownTurtle; if you lack SOL, you can purchase directly on Metamask, transfer from another wallet, or buy on an alternate exchange and transfer to your wallet to switch to $UpSideDownTurtle.",
    },
    {
      image: raydium,
      title: "Go to Raydium",
      description:
        "Connect to Raydium by visiting https://raydium.io, connect your wallet, paste the ……. $UpSideDownTurtle contract address into Raydium, select $UpSideDownTurtle, and confirm the transaction. When prompted by Metamask for a wallet signature, sign to complete the process.",
    },
    {
      image: logo,
      title: "Switch SOL for $UpSideDownTurtle",
      description:
        "Exchange your SOL for $UpSideDownTurtle without worrying about taxes; however, during market volatility, consider adjusting slippage settings if necessary.",
    },
  ];

  const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
      return <p className="break-all">7gEiiRZK6sPmsQ5Ad4yAQiZGT8muogg5pgWcuNcTNtvV</p>;
    } else {
      return <span className="text-orange-400">Coming soon</span>;
    }
  };
  return (
    <div className="">
      <div className="container py-16 mx-auto text-color">
        <Heading title={"How to Buy $UpSideDownTurtle"} />

        <h3 className="text-lg font-bold text-center sm:text-xl md:text-2xl">
          Address to send $Sol <br />{" "}
          <CountdownTimer targetDate={new Date(presale_date).getTime()} />
        </h3>

        <div className="max-w-3xl mx-auto mt-4 text-lg font-medium leading-relaxed tracking-wider text-center md:text-xl">
          {/* All presale tokens will be allocated based on % contributed. Please
          avoid dusting as it may result in receiving nothing during the
          airdrop. Following the airdrop, 1 or 2 liquidity pools will be
          created, with a higher initial price per $UpSideDownTurtle, allowing
          for quicker token dumping. 😄😂" */}
          To ensure equitable distribution, presale tokens will be allocated
          based on the percentage contributed. Please refrain from dusting, as
          it may result in receiving no tokens during the airdrop. Following the
          airdrop, we will establish 1 or 2 liquidity pools with a higher
          initial price per $UpSideDownTurtle, facilitating expedited token
          liquidity
        </div>
        {/* <div className="grid gap-6 mt-10 lg:grid-cols-3">
          {how_to.map((h, i) => (
            <HowToItem key={i} index={i} item={h} />
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default HowToBuy;
